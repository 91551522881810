<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人力法宝</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">在线咨询</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">指派律师</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox">
            <div title="律师" class="searchboxItem ci-full">
              <span class="itemLabel">律师:</span>
              <el-input v-model="searchForm.fullname" type="text" size="small" placeholder="请输入律师名称" clearable />
            </div>
            <div title="所在律所" class="searchboxItem ci-full">
              <span class="itemLabel">所在律所:</span>
              <el-select clearable v-model="searchForm.compId" size="small" filterable remote
                :remote-method="searchCopmp" placeholder="请至少输入两个字搜索">
                <el-option v-for="item in compList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="60px" fixed="left" :index="indexMethod" />
              <el-table-column label="律师" align="left" prop="fullname" show-overflow-tooltip min-width="150px" />
              <el-table-column label="所属律所" align="center" prop="compName" />
              <el-table-column label="操作" align="center" width="120px" fixed="right">
                <template slot-scope="{ row }">
                  <el-button size="small" type="text" @click="okAssign(row)">确认指派</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "manpower_consult_assign",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 上个页面传过来的数据
      lastPageData: {
        consultId: "", // 考场id
      },
      // 搜索条件
      searchForm: {
        fullname: '', // 律师,
        compId: '', // 律所id
      },
      // 所在律所 - 下拉数据
      compList: [],
    }
  },
  computed: {},
  created() {
    this.lastPageData = this.$route?.query ?? {};
  },
  mounted() { },
  methods: {
    // 获取律所 - 下拉数据
    searchCopmp(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/likeCompName", {
          compName: query,
          compType: '60'
        }).then(
          (res) => {
            this.compList = res.data;
          }
        );
      } else {
        this.compList = [];
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.searchForm
      }
      this.doFetch({
        url: "/sys/admin/lawyer/page",
        params,
        pageNum,
      });
    },
    // 确认指派
    okAssign(row) {
      this.$post("/biz/consult/mgt/assign/handleUser", {
        handleUserId: row.adminId,
        consultId: this.lastPageData.consultId
      },
        3000, true, 9)
        .then((res) => {
          this.$message({
            message: "指派成功",
            type: "success",
            duration: 2000
          })
          this.$router.back();
        }).catch(() => {

        });
    },
  },
}
</script>
<style lang="less" scoped></style>